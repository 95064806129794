.a{
    display: flex;
    flex-direction: column;
    background-color: #05386b;
    height: auto;
}

.a-h1{
    text-align: center;
    color: #edf5e1;
    font-size: 36px;
    margin-bottom: 5px;
}

.a-content{
    flex: 1;
    padding: 20px;
    font-size: large;
    color: #edf5e1;
}

.a-card1{
    width: 90%;
    height: auto;
    border-radius: 30px;
    font-size: 32px;
    background-color: #edf5e1;
    color: #333;
    padding: 8px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.a-card2{
    width: 90%;
    height: auto;
    border-radius: 30px;
    padding: 8px;
    background-color: #edf5e1;
    color: #333;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.a-card-imgs{
    display: flex;
    flex-wrap: wrap;
}