.imageContainer{
    background-color: #edf5e1;
    margin: auto;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}

.image{
    padding-left: 1em;
    padding-right: 1em;
    width: 100px;
    height: auto;
}