#i{
    display: flex;
    flex-direction: column;
    background-color: #379683;
    height: auto;
    align-items: center;
}

.i-content{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    align-items: center;
}

.i-profilePic{
    border: 10px solid;
    border-radius: 90%;
    border-color: #8ee4af;
    width: 200px;
    height: 200px;
}

h1{
    text-align: center;
    letter-spacing: 9px;
    font-size: 48px;
    margin-bottom: 5px;
}

h3{
    text-align: center;
    letter-spacing: 5px;
    font-size: 24px;
    margin-bottom: 50px;
}

.i-buttons{
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
}

svg{
    margin: 20px;
}

svg:hover{
    color: #8ee4af;
}

.i-icon{
    height: 70px;
    width: 70px;
    color: black;
    cursor: pointer;
    position: relative;
}

.i-scroll{
    width:50px;
    height: 50px;
    position: relative;
    cursor: pointer;
    bottom: 0px;
}

.i-scroll:hoover{
    color: #8ee4af;
}